<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Fields List</h4>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <router-link
            :to="{ name: 'Create Fields' }"
            :disabled="!hasRole('create')"
            class="btn pull-right btn-border"
            >ADD FIELDS</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table v-loading="loading" :data="tableData">
            <el-table-column
              min-width="300"
              prop="title"
              label="Title"
            ></el-table-column>
            <el-table-column
              min-width="200"
              prop="code"
              label="Code"
            ></el-table-column>
            <el-table-column
              min-width="150"
              prop="applicable"
              label="Applicable"
            ></el-table-column>
            <el-table-column
              min-width="150"
              prop="status"
              label="Status"
            ></el-table-column>
            <el-table-column
              min-width="150"
              header-align="right"
              label="Actions"
            >
              <template slot-scope="scope">
                <div class="text-right table-actions">
                  <el-tooltip content="Edit" :open-delay="300" placement="top">
                    <n-button
                      @click.native="editCustomField(scope.row)"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                  </el-tooltip>

                  <el-tooltip
                    content="Delete"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button
                      @click.native="confirmDeleteCustomField(scope.row)"
                      type="danger"
                      size="sm"
                      icon
                      :disabled="!hasRole('delete')"
                    >
                      <i class="now-ui-icons ui-1_simple-remove"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pull-right py-3">
        <n-pagination
          type="info"
          :pageCount="pagination.totalPages"
          :perPage="pagination.perPage"
          v-model="currentPage"
          @input="changePage"
        ></n-pagination>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { Table, TableColumn, MessageBox, Loading } from "element-ui";
import { Checkbox, Pagination } from "src/components";
import {findUserRole, userRolesCheck} from "@/util/userRoles";
import Helper from "@/util/helper";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
  },
  created: function () {
    userRolesCheck("read", "extra_fields");
    this.getList({ page: 1 })
      .then((response) => {
        this.loading = false;
        this.currentPage = this.pagination.currentPage;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      currentPage: 0,
    };
  },
  computed: {
    ...mapState("customFields", ["pagination"]),
    ...mapGetters("customFields", ["tableData"]),
  },
  methods: {
    ...mapActions("customFields", ["getList", "deleteCustomField"]),
    hasRole(role){
      return findUserRole(role, "extra_fields");
    },
    editCustomField(customField) {
      this.$router.push({
        name: "Update Custom Field",
        params: { code: customField.code, slug: Helper.convertToSlug(customField.title) },
      });
    },
    confirmDeleteCustomField(customField) {
      MessageBox.confirm(
        `This will permanently delete custom field "${customField.title}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.deleteCustomField(customField)
            .then((response) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete custom field success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete custom field failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.loading = true;
      this.getList({ page })
        .then((response) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-table thead {
  color: #000000 !important;
}
</style>
