<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(btnSubmitClick)">
      <card>
        <h4 slot="header" class="card-title">{{ formTitle }}</h4>
        <div>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <label class="col-sm-3 col-form-label">Field Title</label>
                    <div class="col-sm-7">
                      <ValidationProvider
                        name="fieldTitle"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <fg-input
                          type="text"
                          :error="
                            failed
                              ? 'The Required text field is required'
                              : null
                          "
                          :hasSuccess="passed"
                          v-model="customField.title"
                        ></fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-3 col-form-label label-padding-top">Field Type</label>
                    <div class="col-sm-7">
                      <div class="form-group">
                        <ValidationProvider name="fieldType" rules="required" v-slot="{ failed }">
                          <el-select
                            class="select-info"
                            size="large"
                            placeholder="Select..."
                            v-model="customField.type"
                          >
                            <el-option
                              v-for="option in fieldTypes"
                              class="select-info"
                              :value="option.code"
                              :label="option.label"
                              :key="option.label"
                            ></el-option>
                          </el-select>
                          <span class="text-danger invalid-feedback" style="display: block;">
                            {{
                            failed
                            ? `The Required select field is required`
                            : null
                            }}
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div v-if="showOption">
                    <div class="row align-items-center" style="margin-top: 12px;">
                      <label class="col-sm-3 col-form-label">Option</label>
                      <div class="col-sm-7">
                        <n-button
                          @click.native="addOption"
                          type="info"
                          class="pull-right btn-border btn-border-small"
                          :disabled="disableAddOption"
                        >ADD OPTION</n-button>
                      </div>
                    </div>
                    <div
                      v-for="(option, index) in customField.values"
                      :key="index"
                      class="row align-items-center"
                    >
                      <div class="col-sm-9">
                        <div class="form-group">
                          <input
                            aria-describedby="addon-right addon-left"
                            type="text"
                            class="form-control"
                            :name="'option' + index"
                            v-model="customField.values[index]"
                          />
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <a
                          href="#"
                          @click="deleteOption(option)"
                          class="pull-right text-danger pb-2"
                        >X</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <label class="col-sm-3 col-form-label">Code</label>
                    <div class="col-sm-7">
                      <ValidationProvider name="code" rules="required|alpha_dash" v-slot="{ passed, failed }">
                        <fg-input
                          type="text"
                          :disabled="role === 'update'"
                          :error="
                            failed
                              ? 'The Required text field is required and only accept alphabetic characters, numbers, dashes or underscores'
                              : null
                          "
                          :hasSuccess="passed"
                          v-model="customField.code"
                        ></fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-3 col-form-label label-padding-top">Applicable To</label>
                    <div class="col-sm-7">
                      <div class="form-group">
                        <ValidationProvider name="applyTo" rules="required" v-slot="{ failed }">
                          <el-select
                            class="select-info"
                            size="large"
                            placeholder="Select..."
                            v-model="customField.apply_to"
                          >
                            <el-option
                              v-for="option in applyToList"
                              class="select-info"
                              :value="option.code"
                              :label="option.label"
                              :key="option.label"
                            ></el-option>
                          </el-select>
                          <span class="text-danger invalid-feedback" style="display: block;">
                            {{
                            failed
                            ? `The Required select field is required`
                            : null
                            }}
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center" style="margin-top: 5px;">
                    <label class="col-sm-3 col-form-label" style="margin-bottom: 0px;">Status</label>
                    <div class="col-sm-7" style="padding-left: 18px;">
                      <ValidationProvider
                        name="status"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <n-switch
                          color="blue"
                          :error="
                            failed
                              ? 'The Required text field is required'
                              : null
                          "
                          :hasSuccess="passed"
                          v-model="customField.status"
                          type="primary"
                          on-text="ON"
                          off-text="OFF"
                        ></n-switch>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
      <div class="row">
        <div class="col-md-12">
          <n-button native-type="submit" type="info" :disabled="!hasRole()">SUBMIT</n-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { Select, Option, Loading, MessageBox } from "element-ui";

import { Switch } from "src/components/index";

import { extend } from "vee-validate";
import { required, alpha_dash } from "vee-validate/dist/rules";
import {findUserRole} from "@/util/userRoles";

extend("required", required);
extend("alpha_dash", alpha_dash);

export default {
  props: {
    submit: Function,
    formTitle: String,
    role: String
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Switch.name]: Switch,
  },
  created: function () {
    this.RESET_CUSTOM_FIELD();
    this.getApplyToList();
    this.getFieldTypes();
    const code = this.$route.params.code;
    if (typeof code !== "undefined") {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });

      const module = `custom field with id: ${code}, name: ${this.$route.params.slug}`;

      this.getCustomField({ code, module })
        .then((response) => {
          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    }
  },
  computed: {
    ...mapState("references", ["applyToList", "fieldTypes"]),
    ...mapState("customFields", ["customField"]),
    ...mapGetters("customFields", ["disableAddOption"]),
    showOption: function () {
      const types = this.fieldTypes.filter(
        (item) => item.code === this.customField.type
      );
      if (types.length) {
        return types[0].is_enum;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("references", ["getApplyToList", "getFieldTypes"]),
    ...mapActions("customFields", [
      "getCustomField",
      "addOption",
      "deleteOption",
    ]),
    ...mapMutations("customFields", ["RESET_CUSTOM_FIELD"]),
    btnSubmitClick() {
      this.submit();
    },
    hasRole() {
      return findUserRole(this.role, "extra_fields");
    }
  },
};
</script>
<style scoped>
/* .el-select {
  width: 100%;
  height: 50px;
}
.label-padding-top {
  padding-top: 20px;
} */
.bootstrap-switch {
  margin-bottom: 0px;
}
.btn-border-small {
  padding: 5px 10px;
  font-size: 12px;
}
</style>
