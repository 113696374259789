<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">
          Point Reporting Week #{{ weekNumber }} ({{ reportDate }})
        </h4>
      </div>
      <div class="row">
        <div class="col-md-2">
          <fg-input
            placeholder="Email, Card Number, Name, Phone"
            addon-right-icon="fa fa-search"
            v-model="reportingFilter.search"
          ></fg-input>
        </div>
        <div class="col-md-2">
          <div style="margin-top: -12px;">
            <el-select
              v-model="reportingFilter.status"
              class="select-info"
              filterable
              placeholder="Status 1 (Transaction & Core Points)"
            >
              <el-option
                class="select-info"
                v-for="(option, i) in status"
                :key="i"
                :label="option.name"
                :value="option.code"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <div style="margin-top: -12px;">
            <el-select
              v-model="reportingFilter.status_alt"
              class="select-info"
              filterable
              placeholder="Status 2 (Member & Core Points)"
            >
              <el-option
                class="select-info"
                v-for="(option, i) in statusAlt"
                :key="i"
                :label="option.name"
                :value="option.code"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="pull-right">
            <n-button
              @click.native="btnClearFilterClick"
              class="ml-2 btn-border"
              type="info"
            >CLEAR FILTER</n-button>
            <n-button @click.native="loadData" class="btn" type="info"
            >APPLY FILTER</n-button
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table v-loading="loading" :data="tableDataReporting">
            <el-table-column min-width="250" prop="fullName" label="Name">
              <template scope="scope">
                <span style="color: black">{{ scope.row.fullName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="200"
              prop="cardNumber"
              label="Card Number"
            >
              <template scope="scope">
                <span style="color: black">{{ scope.row.cardNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="300" prop="contact" label="Contact">
              <template scope="scope">
                <span v-if="scope.row.phone !== '' && scope.row.email">
                  <span style="color: black">{{ scope.row.phone }}</span>
                  <br />
                  {{ scope.row.email }}
                </span>
                <span v-else-if="scope.row.phone !== ''">{{
                  scope.row.phone
                }}</span>
                <span v-else-if="scope.row.email !== ''">{{
                  scope.row.email
                }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column min-width="200" prop="rewardsPoints" align="right" label="Member Points">
              <template scope="scope">
                <span style="color: black">{{
                  scope.row.rewardsPoints | number
                }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="220" prop="transactionPoints" align="right" label="Transaction Points">
              <template scope="scope">
                <span style="color: black">{{
                  scope.row.transactionPoints | number
                }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="220" prop="corePoints" align="right" label="TADA Core Points">
              <template scope="scope">
                <span style="color: black">{{
                  scope.row.corePoints | number
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="status"
              label="Status 1"
              align="center"
            >
              <template scope="scope">
                <span style="color: black">{{ scope.row.status }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="status"
              label="Status 2"
              align="center"
            >
              <template scope="scope">
                <span style="color: black">{{ scope.row.statusAlt }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pull-right py-3">
        <!-- <n-pagination
          type="info"
          :pageCount="reportingPagination.totalPages"
          :perPage="reportingPagination.perPage"
          v-model="currentPage"
          @input="changePage"/> -->
        <s-pagination
          type="info"
          :pageCount="reportingPagination.totalPages"
          :perPage="reportingPagination.perPage"
          v-model="currentPage"
          @input="changePage"/>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import {
  Table,
  TableColumn,
  MessageBox,
  Loading,
  DatePicker,
  Select,
  Option
} from "element-ui";
import { Checkbox, Pagination, SimplePagination } from "src/components";
import moment from "moment";
import { userRolesCheck } from "@/util/userRoles";
import Helper from "../../util/helper";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [SimplePagination.name]: SimplePagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  created: function() {
    userRolesCheck("read", "membership");
    // this.getReportDateDetails();
    this.getPointReportingList({ page: 1 })
      .then(response => {
        this.loading = false;
        this.currentPage = this.reportingPagination.currentPage;

        if (this.reportingPagination.reportDate !== "") {
          this.reportDate = moment(String(this.reportingPagination.reportDate)).format("DD MMM YYYY");
          this.weekNumber = this.reportingPagination.reportWeek;
        }
      })
      .catch(error => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      currentPage: 0,
      reportDate: null,
      // reportDate: moment(new Date()).subtract(1, 'days').format('DD MMM YYYY'),
      weekNumber: null,
      firstDateOfWeek: null,
      lastDateOfWeek: null,
      status: [
        { name: "Select Status 1 (Transaction & Core Points)", code: "" },
        { name: "Sync", code: "sync" },
        { name: "Unsync", code: "unsync" }
      ],
      statusAlt: [
        { name: "Select Status 2 (Member & Core Points)", code: "" },
        { name: "Sync", code: "sync" },
        { name: "Unsync", code: "unsync" }
      ]
    };
  },
  computed: {
    ...mapState("members", ["reportingFilter", "reportingPagination"]),
    ...mapGetters("members", ["tableDataReporting"])
  },
  methods: {
    ...mapActions("members", ["getPointReportingList"]),
    ...mapMutations("members", ["SET_REPORTING_SEARCH_KEYWORD", "CLEAR_REPORTING_FILTER"]),
    changePage(page) {
      this.currentPage = page;
      this.loading = true;
      this.getPointReportingList({ page })
        .then(response => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    loadData() {
      this.changePage(1);
    },
    btnClearFilterClick: function() {
      this.CLEAR_REPORTING_FILTER();
      this.changePage(1);
    },
    getDateBasedOnWeekNumber(weekNumber) {
      return moment(new Date().getFullYear() + '-01-01').add(weekNumber, 'weeks').startOf('week').format('DD MMM YYYY')
    },
    getWeekNumberOfMonth() {
      return Math.ceil(moment(new Date()).date() / 7)
    },
    getReportDateDetails() {
      let currentWeek = moment(new Date()).isoWeek()
      let previousWeek = currentWeek < 1 ? 1 : currentWeek - 1
      let weekNumber = this.getWeekNumberOfMonth()

      this.weekNumber = weekNumber < 2 ? 1 : weekNumber - 1
      this.firstDateOfWeek = this.getDateBasedOnWeekNumber(previousWeek)
      this.lastDateOfWeek = this.getDateBasedOnWeekNumber(currentWeek)
    }
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format("DD MMM YYYY, HH:mm");
    }
  }
};
</script>
<style lang="scss"></style>
