<template>
  <level-adjustment-form :submit="submit"></level-adjustment-form>
</template>

<script>
import { mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import LevelAdjustmentForm from "./LevelAdjustmentForm";
import { userRolesCheck } from "@/util/userRoles";
import Swal from "sweetalert2";

export default {
  components: {
    "level-adjustment-form": LevelAdjustmentForm,
  },
  created() {
    userRolesCheck("create", "manual_level_adjustment");
  },
  methods: {
    ...mapActions("levelAdjustment", ["createLevelAdjustment"]),
    formatDate(dateObj) {
      let month = "" + (dateObj.getMonth() + 1);
      let day = "" + dateObj.getDate();
      let year = dateObj.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },
    submit(formData, memberName) {
      formData.effective_date = this.formatDate(formData.effective_date);
      formData.adjust_level = formData.adjust_level.toLowerCase();
      let loadingInstance = Loading.service({
        fullscreen: true
      });
      this.createLevelAdjustment({ data: formData, memberName })
        .then((response) => {
          loadingInstance.close();

          Swal.fire({
            icon: "success",
            title: "Create Level Adjustment success",
            text: "Create saved",
            onClose: () => {
              this.$router.push({ name: "Level Adjustment List" });
            }
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: "Create Level Adjustment failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    },
  },
};
</script>

<style>
</style>
