<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(btnSubmitClick)">
      <div>
        <div class="row">
          <div class="col-md-6">
            <card id="level-setting" title="Level Setting">
              <div slot="header">
                <h4 class="card-title">Gold</h4>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label"
                  >Experiences to achieve</label
                >
                <div class="col-sm-10">
                  <ValidationProvider
                    name="gold experiences to achieve"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['gold'].experiences_to_achieve"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label"
                  >Experiences to retain</label
                >
                <div class="col-sm-10">
                  <ValidationProvider
                    name="gold experiences to retain"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['gold'].experiences_to_retain"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">Nights to achieve</label>
                <div class="col-sm-10">
                  <ValidationProvider
                    name="gold nights to achieve"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['gold'].nights_to_achieve"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">Nights to retain</label>
                <div class="col-sm-10">
                  <ValidationProvider
                    name="gold nights to retain"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['gold'].nights_to_retain"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <card id="level-setting" title="Level Setting">
              <div slot="header">
                <h4 class="card-title">Platinum</h4>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label"
                  >Experiences to achieve</label
                >
                <div class="col-sm-10">
                  <ValidationProvider
                    name="platinum experiences to achieve"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['platinum'].experiences_to_achieve"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label"
                  >Experiences to retain</label
                >
                <div class="col-sm-10">
                  <ValidationProvider
                    name="platinum experiences to retain"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['platinum'].experiences_to_retain"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">Nights to achieve</label>
                <div class="col-sm-10">
                  <ValidationProvider
                    name="platinum nights to achieve"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['platinum'].nights_to_achieve"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">Nights to retain</label>
                <div class="col-sm-10">
                  <ValidationProvider
                    name="platinum nights to retain"
                    rules="required|numeric"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="memberLevels['platinum'].nights_to_retain"
                      :error="failed ? 'Field cannot be negative' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <n-button native-type="submit" type="info" :disabled="!hasRole()">
            SUBMIT
          </n-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { min_value, numeric, required } from "vee-validate/dist/rules";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
import { mapActions, mapState } from "vuex";
import { Loading } from "element-ui";
import Swal from "sweetalert2";

extend("required", required);
extend("numeric", numeric);

export default {
  name: "MemberLevel",
  computed: {
    ...mapState("references", ["memberLevels"]),
  },
  methods: {
    ...mapActions("references", ["getMemberLevels", "putMemberLevel"]),
    btnSubmitClick() {
      this.submit();
    },
    hasRole() {
      return findUserRole(this.role, "system_configuration");
    },
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.putMemberLevel()
        .then((response) => {
          loadingInstance.close();
          Swal.fire({
            icon: "success",
            title: "Member level updated successfully",
            text: "Update success",
            onClose: () => {
              this.$router.push({ name: "Level Settings" });
            },
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: "Update member level failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    },
  },
  created: function () {
    this.getMemberLevels();
    userRolesCheck("read", "system_configuration");
  },
};
</script>
<style scoped></style>
