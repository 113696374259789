<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Member List</h4>
      </div>
      <div class="row">
        <div class="col-md-2">
          <fg-input
            placeholder="Email, Card Number, Name, Phone"
            addon-right-icon="fa fa-search"
            v-model="filter.search"
          ></fg-input>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <el-select
              v-model="filter.status"
              class="select-info"
              filterable
              placeholder="Status"
            >
              <el-option
                class="select-info"
                v-for="item in status"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <fg-input style="width: 100%">
            <el-date-picker
              v-model="filter.join_date"
              type="daterange"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </fg-input>
        </div>
        <div class="col-md-6">
          <div class="pull-right">
            <n-button
              @click.native="btnClearFilterClick"
              class="ml-2 btn-border"
              type="info"
            >CLEAR FILTER</n-button>
            <n-button @click.native="loadData" class="btn" type="info"
            >APPLY FILTER</n-button
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table v-loading="loading" :data="tableData">
            <el-table-column min-width="250" prop="fullName" label="Name">
              <template scope="scope">
                <span style="color: black">{{ scope.row.fullName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="200"
              prop="cardNumber"
              label="Card Number"
            >
              <template scope="scope">
                <span style="color: black">{{ scope.row.cardNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="300" prop="contact" label="Contact">
              <template scope="scope">
                <span v-if="scope.row.phone !== '' && scope.row.email">
                  <span style="color: black">{{ scope.row.phone }}</span>
                  <br />
                  {{ scope.row.email }}
                </span>
                <span v-else-if="scope.row.phone !== ''">{{
                  scope.row.phone
                }}</span>
                <span v-else-if="scope.row.email !== ''">{{
                  scope.row.email
                }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column min-width="100" prop="level" label="Level">
              <template scope="scope">
                <span style="color: black">{{ scope.row.level }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="150" prop="points" align="right" label="Points">
              <template scope="scope">
                <span style="color: black">{{
                  scope.row.points | number
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="200"
              prop="memberSince"
              label="Member Since"
            >
              <template scope="scope">
                <span style="color: black">{{
                  scope.row.memberSince | formatDate
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="status"
              label="Status"
              align="center"
            >
              <template scope="scope">
                <span style="color: black">{{ scope.row.status }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              header-align="center"
              label="Actions"
            >
              <template slot-scope="scope">
                <div class="text-center table-actions">
                  <el-tooltip
                    content="Detail"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button
                      @click.native="detailMember(scope.row)"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="now-ui-icons tech_tv"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pull-right py-3">
        <n-pagination
          type="info"
          :pageCount="pagination.totalPages"
          :perPage="pagination.perPage"
          v-model="currentPage"
          @input="changePage"/>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import {
  Table,
  TableColumn,
  MessageBox,
  Loading,
  DatePicker,
  Select,
  Option
} from "element-ui";
import { Checkbox, Pagination } from "src/components";
import moment from "moment";
import { userRolesCheck } from "@/util/userRoles";
import Helper from "../../util/helper";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  created: function() {
    userRolesCheck("read", "membership");
    this.getList({ page: 1 })
      .then(response => {
        this.loading = false;
        this.currentPage = this.pagination.currentPage;
      })
      .catch(error => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      currentPage: 0,
      status: [
        { name: "Active", code: "1" },
        { name: "Inactive", code: "2" },
        { name: "Activation", code: "3" },
        { name: "Inactive", code: "4" },
        { name: "Inactive", code: "5" },
        { name: "Deactivated", code: "0" },
        { name: "Hibernated", code: "6" },
        { name: "Dormant", code: "7" }
      ],
    };
  },
  computed: {
    ...mapState("members", ["filter", "pagination"]),
    ...mapGetters("members", ["tableData"])
  },
  methods: {
    ...mapActions("members", ["getList"]),
    ...mapMutations("members", ["SET_SEARCH_KEYWORD", "CLEAR_FILTER"]),
    changePage(page) {
      this.currentPage = page;
      this.loading = true;
      this.getList({ page })
        .then(response => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    detailMember(data) {
      this.$router.push({
        name: "Member Details",
        params: {
          cardNumber: data.cardNumber,
          slug: Helper.convertToSlug(data.fullName)
        }
      });
    },
    loadData() {
      this.changePage(1);
    },
    btnClearFilterClick: function() {
      this.CLEAR_FILTER();
      this.changePage(1);
    }
  },
  filters: {
    formatBirthday(value) {
      if (value == null) return "";

      return moment(String(value)).format("DD MMM YYYY");
    },
    formatDate(value) {
      return moment(String(value)).format("DD MMM YYYY, HH:mm");
    }
  }
};
</script>
<style lang="scss"></style>
