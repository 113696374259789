import { USER_ROLES } from "@/config";
import Swal from "sweetalert2";

function findUserRole(accessLevel, modules) {
  let accessList = JSON.parse(localStorage.getItem(USER_ROLES));
  if (accessList.length === 0) {
    return true;
  }

  let found = null;
  accessList.forEach(arr => {
    arr.modules.forEach(mod => {
      if (!found) {
        if (mod.code === modules) {
          found = mod.access.find(x => x === accessLevel);
        }
      }
    });
  });

  return found;
}

function userRolesCheck(accessLevel, modules) {
  let accessList = JSON.parse(localStorage.getItem(USER_ROLES));

  if (accessList.length === 0) {
    return;
  }

  const found = findUserRole(accessLevel, modules);

  if (!found) {
    Swal.fire({
      icon: "error",
      title: "Invalid Access",
      text:
        "You are not authorized to access this page. Please contact administrator.",
      onClose: () => {
        window.location.href = "/dashboard";
      }
    });
  }
}

export { findUserRole, userRolesCheck };
