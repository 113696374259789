<template>
  <form-role :submit="submit" admin-role="update"></form-role>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { Loading } from "element-ui";
import FormRole from "./FormRole";
import {userRolesCheck} from "@/util/userRoles";
import Swal from "sweetalert2";

export default {
  components: {
    "form-role": FormRole,
  },
  computed: {
    ...mapState("roles", ["role"]),
  },
  methods: {
    ...mapActions("roles", ["saveRole"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.saveRole()
        .then((response) => {
          loadingInstance.close();

          Swal.fire({
            icon: "success",
            title: "Update role success",
            text: "Update saved",
            onClose: () => {
              this.$router.push({ name: "Roles" });
            }
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: error.response.data.error.message,
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    },
  },
};
</script>
<style scoped>
.el-select {
  width: 100%;
}
.label-padding-top {
  padding-top: 20px;
}
.bootstrap-switch {
  margin-bottom: 0px;
}
</style>
