<template>
  <div>
    <card>
      <div class="row justify-content-center" style="margin-top: 10px">
        <div class="col-md-11">
          <div slot="header">
            <h4 class="card-title">Rewards List</h4>
          </div>
          <div class="row" style="padding-bottom: 20px">
            <div class="col-sm-4" style="padding-top: 10px">
              <fg-input
                placeholder="Search"
                v-model="filter.name"
                @change="handleSearch"
              ></fg-input>
            </div>
          </div>
          <div class="row justify-content-start">
            <div
              class="col-md-4 col-lg-3 col-sm-6"
              v-for="voucher in vouchers"
              :key="voucher.id"
            >
              <router-link
                :to="{
                  name: 'Rewards Detail',
                  params: {
                    id: voucher.id,
                    slug: getSlugFromLangs(voucher.langs)
                  },
                  query: { card_number: $route.query.card_number }
                }"
              >
                <card class="card-item" style="height: 450px">
                  <el-image
                    fit="cover"
                    style="width: 100%; height: 200px"
                    :src="voucher.image"
                    :lazy="true"
                  >
                    <div slot="placeholder" class="image-slot">
                      Loading
                      <span class="dot">...</span>
                    </div>
                  </el-image>
                  <div style="padding-left: 20px; padding: 20px 20px 0px 20px">
                    <div style="min-height: 80px">
                      <p
                        class="card-text"
                        style="font-size: 11pt"
                        v-for="lang in voucher.langs"
                        :key="lang.lang"
                      >
                        {{ lang.name }}
                      </p>
                    </div>
                    <hr />
                    <div>
                      <span>Points Required</span>
                      <span class="pull-right font-weight-bold">
                        {{ separateNumber(voucher.value) }} Pts
                      </span>
                    </div>
                  </div>
                </card>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="pull-right py-3">
        <n-pagination
          type="info"
          :pageCount="pagination.totalPages"
          :perPage="pagination.perPage"
          v-model="currentPage"
          @input="changePage"
        ></n-pagination>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Image, Select, Option } from "element-ui";
import { Pagination } from "src/components";
import { userRolesCheck } from "@/util/userRoles";
import Helper from "../../util/helper";
export default {
  components: {
    [Image.name]: Image,
    [Select.name]: Select,
    [Option.name]: Option,
    [Pagination.name]: Pagination
  },
  created: function() {
    userRolesCheck("read", "reward_catalog");
    this.getVoucherTypes();
    this.getRewards({ page: 1, module: `reward list`, pageName: `reward` });
  },
  data() {
    return {
      loading: true,
      currentPage: 0
    };
  },
  filters: {
    number(value) {
      return new Intl.NumberFormat().format(value);
    }
  },
  computed: {
    ...mapState("rewards", ["vouchers", "filter", "pagination"]),
    ...mapState("references", ["voucherTypes"])
  },
  methods: {
    ...mapActions("references", ["getVoucherTypes"]),
    ...mapActions("rewards", ["getRewards"]),
    ...mapMutations("rewards", ["CLEAR_FILTER"]),
    handleSearch: function() {
      this.getRewards({ page: 1 });
    },
    changePage(page) {
      this.currentPage = page;
      this.getRewards({ page });
    },
    separateNumber(x) {
      return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getSlugFromLangs(langs) {
      if (langs.length) {
        const lang = langs[0];
        return Helper.convertToSlug(lang.name);
      }
      return "no-name";
    },
  },
};
</script>
<style>
.card-item .card-body {
  padding: 0px !important;
}
</style>
