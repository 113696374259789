<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(btnSubmitClick)">
      <card>
        <div slot="header">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">
                {{ template.code.replace(/_/g, " ").toUpperCase() }}
              </h4>
            </div>
            <div class="col-md-6">
              <n-button
                @click.native="closeDetail"
                class="pull-right btn-border"
                type="info"
                >CANCEL</n-button
              >
              <el-select
                class="select-info pull-right"
                style="width: 185px; margin-right: 10px; margin-top: 2px;"
                size="small"
                placeholder="Select language"
                v-model="activeLang"
                :filterable="true"
                :disabled="disableSelectLang"
              >
                <el-option
                  v-for="option in languages"
                  class="select-info"
                  :value="option.code"
                  :label="option.country"
                  :key="option.code"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row">
              <label class="col-sm-2 col-form-label">Subject: </label>
              <div class="col-sm-10">
                <fg-input
                  type="text"
                  :error="failed ? 'Subject is required' : null"
                  :hasSuccess="passed"
                  v-model="activeSubject"
                ></fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <trumbowyg
                  v-model="activeTemplate"
                  @tbw-change="onBlurTemplate"
                  :config="editorConfig"
                  class="form-control"
                  name="content"
                ></trumbowyg>
              </div>
            </div>
          </div>
        </div>
      </card>
      <div class="row">
        <div class="col-md-12">
          <n-button
            @click.native="closeDetail"
            type="info"
            class="btn btn-default  btn-border"
            >CANCEL</n-button
          >
          <n-button native-type="submit" class="pull-right" type="info">
            SUBMIT
          </n-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { Loading, Select, Option } from "element-ui";
import { userRolesCheck } from "@/util/userRoles";
import Trumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/ui/trumbowyg.css";
import Swal from "sweetalert2";

export default {
  components: {
    Trumbowyg,
    [Select.name]: Select,
    [Option.name]: Option
  },
  created: function() {
    userRolesCheck("read", "system_configuration");
    this.getLanguages();
    const code = this.$route.params.code;
    const name = this.$route.params.slug.split("-").join(" ");
    if (typeof code !== "undefined") {
      this.code = code;
      this.loadTemplatePerLanguage();
    }
  },
  data: function() {
    return {
      editorConfig: {
        //http://alex-d.github.io/Trumbowyg/documentation.html#basic-options
      },
      code: "",
      title: "",
      subject: "",
      activeLang: `EN`,
      activeSubject: "",
      activeTemplate: "",
      disableSelectLang: true
    };
  },
  computed: {
    ...mapState("references", ["languages"]),
    ...mapState("notification", ["template"]),
    onChangeActiveLang: function() {
      return this.activeLang;
    },
    onChangeActiveSubject: function() {
      return this.activeSubject;
    }
  },
  watch: {
    onChangeActiveLang: function(newLang, oldLang) {
      if (newLang !== oldLang) {
        this.getActiveTemplateData();
      }
    },
    onChangeActiveSubject: function(newSubject, oldSubject) {
      if (newSubject !== oldSubject) {
        this.updateEmailTemplate();
      }
    }
  },
  methods: {
    ...mapActions("notification", [
      "getTemplatePerLanguage",
      "getTemplate",
      "saveTemplate"
    ]),
    ...mapActions("references", ["getLanguages"]),
    ...mapMutations("notification", ["UPDATE_TEMPLATE_CONTENT_BY_LANG"]),
    closeDetail() {
      this.$router.push({
        name: "Settings"
      });
    },
    updateEmailTemplate() {
      this.UPDATE_TEMPLATE_CONTENT_BY_LANG({
        lang: this.activeLang,
        subject: this.activeSubject,
        template: this.activeTemplate
      });
    },
    onBlurTemplate(event) {
      this.updateEmailTemplate();
    },
    async btnSubmitClick() {
      let loadingInstance = Loading.service({
        fullscreen: true
      });
      this.saveTemplate()
        .then(response => {
          loadingInstance.close();

          Swal.fire({
            icon: "success",
            title: "Update template success",
            text: "Update saved",
            onClose: () => {
              this.$router.push({ name: "Settings" });
            }
          });
        })
        .catch(error => {
          loadingInstance.close();

          this.$notify({
            message: "Update template failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    },
    getActiveTemplateData() {
      if (this.template) {
        const selectedTemplates = this.template.langs.filter(
          item => item.lang === this.activeLang
        );

        if (selectedTemplates.length) {
          const selectedTemplate = selectedTemplates[0];
          this.activeSubject = selectedTemplate.subject;
          this.activeTemplate = selectedTemplate.template;
        } else {
          this.activeSubject = "";
          this.activeTemplate = "";
          this.$notify({
            message: `Template not found for selected language`,
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        }
      }
    },
    loadTemplatePerLanguage() {
      this.disableSelectLang = true;
      let loadingInstance = Loading.service({
        fullscreen: true
      });
      // this.getTemplatePerLanguage({ code: this.code, language: this.activeLang, name })
      //   .then((response) => {
      //     this.title = response.data.data.code.replace(/_/g, " ").toUpperCase();
      //     this.subject = response.data.data.subject;
      //     this.template = response.data.data.template;
      //     loadingInstance.close();
      //     this.disableSelectLang = false;
      //   })
      //   .catch((error) => {
      //     loadingInstance.close();
      //     this.disableSelectLang = false;
      //     this.$notify({
      //       message: `Template not found for selected language`,
      //       timeout: 5000,
      //       icon: "now-ui-icons ui-1_bell-53",
      //       horizontalAlign: "center",
      //       verticalAlign: "top",
      //       type: "danger"
      //     });
      //   });
      this.getTemplate(this.code)
        .then(response => {
          loadingInstance.close();
          this.disableSelectLang = false;
          this.getActiveTemplateData();
        })
        .catch(error => {
          loadingInstance.close();
          this.disableSelectLang = false;
          this.$notify({
            message: `Template not found`,
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    }
  }
};
</script>

<style>
.trumbowyg-box,
.trumbowyg-editor {
  min-height: 800px !important;
}
</style>
