<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Voucher List</h4>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <router-link
            :to="{ name: 'Create Voucher' }"
            :disabled="!hasRole('create')"
            class="btn pull-right btn-border"
          >
            ADD VOUCHER
          </router-link>
        </div>
      </div>
      <div class="row" style="padding-bottom: 20px">
        <div class="col-sm-2" style="padding-top: 10px">
          <fg-input
            placeholder="Search voucher"
            addon-right-icon="fa fa-search"
            v-model="filter.name"
          ></fg-input>
        </div>
        <div class="col-sm-4">
          <el-select
            class="select-info px-1"
            size="large"
            placeholder="Select Category"
            v-model="filter.category"
            style="width: 30%"
          >
            <el-option
              v-for="option in categoryList"
              class="select-info"
              :value="option.category_id"
              :label="option.name"
              :key="option.category_id"
            ></el-option>
          </el-select>
          <el-select
            class="select-info px-1"
            size="large"
            placeholder="Select Property"
            v-model="filter.property"
            style="width: 30%"
          >
            <el-option
              v-for="option in propertyList"
              class="select-info"
              :value="option.code"
              :label="option.langs[0].name"
              :key="option.code"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-3">
          <div style="padding-top: 11px; padding-bottom: 11px; display: flex">
            <div style="line-height: 2.5"><span>Points</span></div>
            <fg-input class="ml-3" placeholder="1000" v-model="filter.pointFrom"></fg-input>
            <div style="line-height: 2.5;margin-left: 10px"><span>-</span></div>
            <fg-input class="ml-3" placeholder="15000" v-model="filter.pointTo"></fg-input>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="pull-right">
            <n-button
              @click.native="btnClearFilterClick"
              class="ml-2 btn-border"
              type="info"
            >
              CLEAR FILTER
            </n-button>
            <n-button
              @click.native="btnApplyFilterClick"
              class="ml-2"
              type="info"
            >
              APPLY FILTER
            </n-button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" style="margin-top: 10px">
        <div class="col-lg-8 col-md-10">
          <div class="row">
            <div class="col-md-4" v-for="voucher in vouchers" :key="voucher.id">
              <router-link
                :to="{
                  name: 'Edit Voucher',
                  params: {
                    id: voucher.id,
                    slug: getSlugFromLangs(voucher.langs)
                  }
                }"
              >
                <card class="card-item" style="height: 500px">
                  <el-image
                    fit="cover"
                    style="width: 100%; height: 200px"
                    :src="voucher.image"
                    :lazy="true"
                  >
                    <div slot="placeholder" class="image-slot">
                      Loading
                      <span class="dot">...</span>
                    </div>
                  </el-image>
                  <div style="padding: 20px 20px 0px 20px">
                    <div style="height: 80px">
                      <p
                        class="card-text"
                        style="font-size: 11pt"
                        v-for="lang in voucher.langs"
                        :key="lang.lang"
                      >
                        {{ lang.name }} - {{ voucher.value | number }} Pts
                      </p>
                    </div>
                    <hr />
                    <div>
                      <span>Property</span>
                      <span class="pull-right font-weight-bold">
                        {{ voucher.property | formatProperty }}
                      </span>
                    </div>
                    <div>
                      <span>Status</span>
                      <span :class="parseStatusStyle(voucher.status)">{{
                        voucher.status | formatStatus
                      }}</span>
                    </div>
                    <div>
                      <span>Position</span>
                      <span class="pull-right font-weight-normal">
                        {{ voucher.weight }}
                      </span>
                    </div>
                    <div>
                      <span>Stock</span>
                      <span class="pull-right font-weight-normal">
                        {{ voucher.quantity | formatQuantity }}
                      </span>
                    </div>
                    <hr />
                    <p
                      class="text-center font-weight-bold text-capitalize"
                      style="color: #52c1e9"
                    >
                      {{ voucher.type }}
                    </p>
                  </div>
                </card>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="pull-right py-3">
        <n-pagination
          type="info"
          :pageCount="pagination.totalPages"
          :perPage="pagination.perPage"
          v-model="currentPage"
          @input="changePage"
        ></n-pagination>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Image, Select, Option } from "element-ui";
import { Pagination } from "src/components";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
import Helper from "../../util/helper";

export default {
  components: {
    [Image.name]: Image,
    [Select.name]: Select,
    [Option.name]: Option,
    [Pagination.name]: Pagination
  },
  created: function() {
    userRolesCheck("read", "voucher_management");
    this.getVoucherTypes();
    this.getCategoryList();
    this.getPropertyList();
    this.getVouchers({ page: 1, module: `voucher list` }).then((response) => {
      this.currentPage = this.pagination.currentPage;
    });
  },
  data() {
    return {
      loading: true,
      currentPage: 0
    };
  },
  computed: {
    ...mapState("vouchers", ["vouchers", "filter", "pagination"]),
    ...mapState("references", ["voucherTypes", "propertyList", "categoryList"])
  },
  filters: {
    formatQuantity: function (value) {
      return value == null ? "-" : value;
    },
    formatStatus: function (value) {
      return value ? "Active" : "Inactive";
    },
    formatProperty: function (value) {
      if (value.length > 1) {
        return "Multiple";
      } else {
        return value.length === 1 ? value[0] : "-";
      }
    }
  },
  methods: {
    ...mapActions("references", [
      "getVoucherTypes",
      "getCategoryList",
      "getPropertyList"
    ]),
    ...mapActions("vouchers", ["getVouchers"]),
    ...mapMutations("vouchers", ["CLEAR_FILTER"]),
    hasRole(role) {
      return findUserRole(role, "voucher_management");
    },
    btnApplyFilterClick: function () {
      this.getVouchers({ page: 1 });
    },
    btnClearFilterClick: function () {
      this.CLEAR_FILTER();
      this.getVouchers({ page: 1 });
    },
    parseStatusStyle: function (status) {
      if (status) {
        return "pull-right font-weight-bold text-success";
      } else {
        return "pull-right font-weight-bold text-danger";
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.getVouchers({ page });
    },
    getSlugFromLangs(langs) {
      if (langs.length) {
        return Helper.convertToSlug(langs[0].name);
      }
      return "no-name";
    }
  }
};
</script>
