var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('card',[_c('div',{staticClass:"row justify-content-center",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"col-md-11"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Rewards List")])]),_c('div',{staticClass:"row",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticClass:"col-sm-4",staticStyle:{"padding-top":"10px"}},[_c('fg-input',{attrs:{"placeholder":"Search"},on:{"change":_vm.handleSearch},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)]),_c('div',{staticClass:"row justify-content-start"},_vm._l((_vm.vouchers),function(voucher){return _c('div',{key:voucher.id,staticClass:"col-md-4 col-lg-3 col-sm-6"},[_c('router-link',{attrs:{"to":{
                name: 'Rewards Detail',
                params: {
                  id: voucher.id,
                  slug: _vm.getSlugFromLangs(voucher.langs)
                },
                query: { card_number: _vm.$route.query.card_number }
              }}},[_c('card',{staticClass:"card-item",staticStyle:{"height":"450px"}},[_c('el-image',{staticStyle:{"width":"100%","height":"200px"},attrs:{"fit":"cover","src":voucher.image,"lazy":true}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v(" Loading "),_c('span',{staticClass:"dot"},[_vm._v("...")])])]),_c('div',{staticStyle:{"padding-left":"20px","padding":"20px 20px 0px 20px"}},[_c('div',{staticStyle:{"min-height":"80px"}},_vm._l((voucher.langs),function(lang){return _c('p',{key:lang.lang,staticClass:"card-text",staticStyle:{"font-size":"11pt"}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0),_c('hr'),_c('div',[_c('span',[_vm._v("Points Required")]),_c('span',{staticClass:"pull-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm.separateNumber(voucher.value))+" Pts ")])])])],1)],1)],1)}),0)])]),_c('div',{staticClass:"pull-right py-3"},[_c('n-pagination',{attrs:{"type":"info","pageCount":_vm.pagination.totalPages,"perPage":_vm.pagination.perPage},on:{"input":_vm.changePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }