<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form class="form-horizontal" @submit.prevent="handleSubmit(btnSubmitClick)">
      <card>
        <h4 slot="header" class="card-title">{{formTitle}}</h4>
        <div>
          <div class="row">
            <label class="col-sm-2 col-form-label">Languages</label>
            <div class="col-sm-10">
              <div class="form-group">
                <el-select
                  v-model="languagesSelected"
                  class="select-info"
                  multiple
                  size="large"
                  filterable
                  placeholder="Select..."
                  @change="addLanguageOption"
                  @remove-tag="deleteLanguageOption"
                >
                  <el-option
                    class="select-info"
                    v-for="item in languages"
                    :key="item.code"
                    :label="item.country"
                    :value="item.code">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row" v-for="(cat, index) in category" :key=index>
            <label class="col-sm-2 col-form-label">Name ({{cat.lang}})</label>
            <div class="col-sm-10">
              <input
                type="hidden"
                v-model="category[index].lang"
              >
              <fg-input
                type="text"
                v-model="category[index].name"
              ></fg-input>
            </div>
          </div>
        </div>
      </card>
      <div class="row">
        <div class="col-md-12">
          <n-button native-type="submit" type="info" :disabled="!hasRole()">SUBMIT</n-button>
          <button class="btn btn-label" type="button" @click="$router.go(-1)">CANCEL</button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { Select, Option, Loading, MessageBox } from "element-ui";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {findUserRole} from "@/util/userRoles";

extend("required", required);

export default {
  props: {
    submit: Function,
    formTitle: String,
    role: String
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      languagesSelected: [],
    }
  },
  created: function () {
    this.RESET_CATEGORY();
    this.getLanguages();
    const category_id = this.$route.params.category_id;
    if (typeof category_id !== "undefined") {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      const module = `category with id: ${category_id}, name: ${this.$route.params.slug}`;

      this.getCategory({category_id, module})
        .then((response) => {
          this.languagesSelected = this.categoryData.map(item => {
            return item.lang;
          });
          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    }
  },
  computed: {
    ...mapState("references", ["languages"]),
    ...mapState("categories", ["category"]),
    ...mapGetters("categories", ["categoryData"]),
  },
  methods: {
    ...mapActions("references", ["getLanguages"]),
    ...mapActions("categories", [
      "getCategory",
      "addLanguageOption",
      "deleteLanguageOption",
    ]),
    ...mapMutations("categories", ["RESET_CATEGORY"]),
    btnSubmitClick() {
      if (this.category.filter(item => item.lang == "EN").length) {
        this.submit();
      } else {
        this.$notify({
            message: "English category name is required",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
      }
      
    },
    hasRole() {
      return findUserRole(this.role, "category_management");
    }
  }
}
</script>
<style scoped>
.el-select {
  width: 100%;
  height: inherit !important;
}
.el-select .el-tag {
  border-color: transparent !important;
}
.el-select .el-tag__close.el-icon-close {
  color: #FFF !important;
}
</style>
