<template>
  <form-role :submit="submit" admin-role="create"></form-role>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import FormRole from "./FormRole";
import {userRolesCheck} from "@/util/userRoles";
import Swal from "sweetalert2";

export default {
  components: {
    "form-role": FormRole,
  },
  created() {
    userRolesCheck("create", "role");
  },
  methods: {
    ...mapActions("roles", ["saveRole"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.saveRole()
        .then((response) => {
          loadingInstance.close();

          Swal.fire({
            icon: "success",
            title: "Create role success",
            text: "Create saved",
            onClose: () => {
              this.$router.push({ name: "Roles" });
            }
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: "Create role failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    }
  }
};
</script>
<style scoped>
</style>
